import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button,
    Grid,
    InputAdornment,
    TextField,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Pagination,
    PaginationProps,
    Avatar
} from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { uploadIcon, filterIcon, userImage } from "./assets"
import Drawer from "../../dashboard/src/Drawer.web";
import FolderView from "../../../components/src/FolderView";
import FileView from "../../../components/src/FileView";
// Customizable Area End

import DocumentsController, {
    Props,
    configJSON,
} from "./DocumentsController";

// Customizable Area Start
const foldersArray = [
    {id: 1, name: "New Project Template ", count: 33, owner: "me", createdAt: "24 Nov"},
    {id: 2, name: "Product Design – Creating ne", count: 82, owner: "Jennifer Lopez", createdAt: "24 Nov"},
    {id: 3, name: "Startup", count: 151, owner: "me", createdAt: "24 Nov"}
];
  
const filesArray = [
    { fileName: "File name.doc", fileSize: "128 KB", date: "09 Aug 2024, 08:00 PM", owner: "Kathryn Murphy", ownerImage: userImage, background: "" },
    { fileName: "File name.doc", fileSize: "128 KB", date: "08 Aug 2024, 10:45 PM", owner: "Indri Gintan", ownerImage: "", background: "#6366F1"  },
    { fileName: "File name.doc", fileSize: "128 KB", date: "07 Aug 2024, 07:31 PM", owner: "Savannah Nguyen", ownerImage: userImage, background: ""  },
    { fileName: "File name.doc", fileSize: "128 KB", date: "06 Aug 2024, 02:19 AM", owner: "Darrell Steward", ownerImage: userImage, background: ""  },
    { fileName: "File name.doc", fileSize: "128 KB", date: "05 Aug 2024, 06:00 PM", owner: "Christian Hamid", ownerImage: "", background: "#FF784B"  },
    { fileName: "File name.doc", fileSize: "128 KB", date: "05 Aug 2024, 01:00 PM", owner: "Cameron Williamson", ownerImage: userImage, background: ""  },
    { fileName: "File name.doc", fileSize: "128 KB", date: "04 Aug 2024, 11:00 AM", owner: "Leslie Alexander", ownerImage: userImage, background: ""  },
    { fileName: "File name.doc", fileSize: "128 KB", date: "04 Aug 2024, 09:15 AM", owner: "Chris", ownerImage: "", background: "#6366F1"  }
];
  
interface StyledPaginationProps extends PaginationProps {
    totalPages: number;
};
// Customizable Area End

export default class Documents extends DocumentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTextField = () => {
        return(
            <TextField
                type="text"
                variant="outlined"
                placeholder="Search"
                style={{ width: '320px', height: "44px" }}
                inputProps={{ 
                    sx: {
                        color: "#0F172A",
                        fontSize: "16px",
                        "&::placeholder": {
                            color: "#94A3B8",
                            fontSize: "16px"
                        }
                    }
                }}
                InputProps={{
                    style: { borderRadius: '50px', height: '44px' },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchRoundedIcon style={{color: "#94A3B8"}}/>
                        </InputAdornment>
                    )
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                            background: "#ffffff",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CBD5E1"
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CBD5E1"
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                            boxShadow: '0 0 0 1px #4F46E5, 0 0 0 2px #C7D2FE'
                        }
                    },
                    "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
                    }
                }}
            />
        );
    };
    
    renderPaginationRow = () => {
        const { page, totalPages } = this.state;
        return (
            <TableRow>
                <TableCell colSpan={6}>
                    <Box style={webStyle.paginationMainBox}>
                        <Typography style={webStyle.pageText}>
                            Page {page} of {totalPages }
                        </Typography>
                        <StyledPagination
                            data-test-id="paginationTestId"
                            page={page}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            totalPages={totalPages}
                            onChange={this.handlePageChange}
                        />
                    </Box>
                </TableCell>
            </TableRow>
        );
    };
    
    renderTable = () => {
        return (
            <StyledTableContainer>
                <Table>
                    <StyledTableHead>
                        <TableRow>
                            <TableCell>
                                <Box style={webStyle.nameHeadingCell}>
                                    <Box style={webStyle.checkBoxNotSelect}></Box>
                                    Name
                                </Box>
                            </TableCell>
                            <TableCell>Date uploaded</TableCell>
                            <TableCell>Last updated</TableCell>
                            <TableCell>Uploaded by</TableCell>    
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                        {filesArray.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Box style={webStyle.tableFileBox}>
                                        <Box style={webStyle.checkBoxNotSelect}></Box>
                                        <Box
                                            data-test-id="fileBoxTestId"
                                            style={webStyle.fileViewWrapper} 
                                        >
                                            <FileView name={item.fileName} size={item.fileSize}/>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>{item.date}</TableCell>
                                <TableCell>{item.date}</TableCell>
                                <TableCell>
                                    <Box style={webStyle.avatarBox}>
                                        {
                                            item.ownerImage?
                                            <Avatar src={item.ownerImage} style={{width: "24px", height: "24px"}}/>:
                                            <Avatar style={{...webStyle.textAvatar, background: item.background}}>
                                                {this.getAvatarText(item.owner)}
                                            </Avatar>
                                        }
                                        <Typography style={{fontSize: "14px", color: "#0F172A", fontWeight: 500}}>
                                            {item.owner}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell style={{width: "60px", textAlign: "center"}}>
                                    <Typography style={webStyle.tableActionText}>
                                        {configJSON.delete}
                                    </Typography>
                                </TableCell>
                                <TableCell style={{width: "40px", textAlign: "center"}}>
                                    <Typography style={webStyle.tableActionText}>
                                        {configJSON.edit}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        {this.renderPaginationRow()}
                    </StyledTableBody>
                </Table>
            </StyledTableContainer>
        );
      };
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Drawer navigation={this.props.navigation} pageName = "Documents">
                <Box style={webStyle.mainBox}>
                    <Box style={webStyle.headerBox}>
                        <Typography style={webStyle.headingText}>
                            {configJSON.document}
                        </Typography>
                        <UploadButton style={webStyle.buttonStyle} >
                            <Box style={webStyle.buttonInnerBox}>
                                <img src={uploadIcon} alt="upload_icon" />
                                <Typography style={webStyle.buttonText}>{configJSON.upload}</Typography>
                            </Box>
                        </UploadButton>
                    </Box>
                    <Box style={webStyle.folderMainBox}>
                        <Typography style={webStyle.folderText}>
                            {configJSON.folders}
                        </Typography>
                    </Box>
                    <Grid
                        container
                        spacing={3}
                        style={webStyle.folderContainerGrid}
                    >
                        {foldersArray.map((item, index) => 
                            <Grid
                                data-test-id={`folderGrid${index}TestId`}
                                item 
                                sm={4}
                                md={3}
                                key={index}
                            >
                                <Box
                                    data-test-id={`folderBoxTestId${index}`}
                                >   
                                    <FolderView
                                        data-test-id={`folderTestId${index}`}
                                        id={Number(item.id)}
                                        folderName={item.name}
                                        fileCount={item.count.toString()}
                                        iconColor={"#FBBF24"}
                                        showOwner={Boolean(item.owner)}
                                        owner={item.owner}
                                        created={item.createdAt}
                                        createdOrModified={index === 0? "created": "modified"}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <Box style={{...webStyle.headerBox, marginTop: "25px", marginBottom: "23px"}}>
                        <Typography style={{...webStyle.headingText, fontSize: "20px"}}>
                            {configJSON.allFiles}
                        </Typography>
                        <Box>
                            {this.renderTextField()}
                            <FilterButton>
                                <Box>
                                    <img src={filterIcon} alt="filter_icon" />
                                    <Typography>
                                        {configJSON.addFilter}
                                    </Typography>
                                </Box>
                            </FilterButton>
                        </Box>
                    </Box>
                    {this.renderTable()}
                </Box>
            </Drawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const StyledTableContainer = styled(TableContainer)({
    border: "1px solid #E2E8F0",
    borderRadius: "25px",
    overflow: "hidden",
    background: "white"
});
  
const StyledTableHead = styled(TableHead)({
    "& .MuiTableRow-root": {
        backgroundColor: "#F8FAFC"
    },
    "& .MuiTableCell-root": {
        borderRight: "1px solid #E2E8F0",
        borderBottom: "1px solid #E2E8F0",
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 500
    },
    "& .MuiTableCell-root:last-child": {
        borderRight: "none"
    }
});
  
const StyledPagination = styled(Pagination)<StyledPaginationProps>(({ totalPages }) => ({
    "& .MuiPaginationItem-root": {
        margin: "0",
        border: "1px solid #E5E5E5",
        borderRadius: "0px",
        height: "32px",
        boxShadow: "0px 4px 8px -5px #00000026",
        color: "#0F172A",
        borderRight: "1px solid #E2E8F0",
        borderLeft: "none",
        fontWeight: 600,
        fontSize: "12px"
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
        marginLeft: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
        marginRight: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label$='page 1']": {
        borderRadius: "8px 0 0 8px",
        borderLeft: "1px solid #E5E5E5"
    },
    [`& .MuiPaginationItem-root[aria-label$='page ${totalPages}']`]: {
        borderRadius: totalPages === 1? "8px": "0 8px 8px 0",
        borderRight: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root:hover": {
        background: "#E2E8F0"
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        background: "#E2E8F0"
    }
}));
  
const StyledTableBody = styled(TableBody)({
    "& .MuiTableCell-root": {
        borderRight: "1px solid #E2E8F0",
        borderBottom: "1px solid #E2E8F0",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 500,
        padding: "10px 16px"
    },
    "& .MuiTableCell-root:last-child": {
        borderRight: "none"
    }
});
  
const UploadButton = styled(Button)({
    background: "#6366F1",
    "&:hover": {
        background: "#6366F1"  
    },
    "& .MuiTypography-root": {
        fontWeight: 600,
        marginLeft: "10px",
        color: "#FFFFFF"
    }
});
  
const FilterButton = styled(Button)({
    background: "#ffffff",
    border: "1px solid #CBD5E1",
    width: "110px",
    height: "44px",
    borderRadius: "100px",
    marginLeft: "8px",
    textTransform: "none",
    "&:hover": {
        background: "#ffffff"  
    },
    "& .MuiBox-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px"
    },
    "& .MuiTypography-root": {
        fontWeight: 600,
        fontSize: "12px",
        color: "#0F172A"
    }
});
  
const webStyle = {
    mainBox: {
        background: "#fafaf9",
        padding: "24px",
        height: "100%",
        minHeight: "400px"
    },
    textAvatar: {
        width: "24px",
        height: "24px",
        backgroundColor: "#6366F1",
        color: '#FFFFFF',
        fontSize: "10px",
        fontWeight: 800,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransformation: "upper-case"
    } as React.CSSProperties,
    folderMainBox: {
        marginTop: "25px"
    },
    avatarBox: {
        display: "flex",
        alignItems: "center",
        gap: "13px"
    },
    folderContainerGrid: {
        marginTop: "-8px"
    },
    paginationMainBox: {
        background: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "8px",
        paddingBottom: "8px"
    },
    pageText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 500
    },
    tableActionText: {
        fontSize: "14px",
        color: "#6366F1",
        fontWeight: 600,
        cursor: "pointer"
    },
    checkBoxNotSelect: {
        border: "1px solid #CBD5E1",
        background: "#FFFFFF",
        borderRadius: "6px",
        width: "20px",
        height: "20px",
        overflow: "hidden",
        cursor: "pointer"
    },
    tableFileBox: {
        display: "flex",
        gap: "15px",
        alignItems: "center"
    },
    nameHeadingCell: {
        display: "flex",
        gap: "15px"
    },
    fileViewWrapper: {
        width: "100%",
        cursor: "pointer"
    },
    folderText: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600
    },
    headerBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    headingText:{
        fontSize: "24px",
        fontWeight: 600,
        color: "#0F172A"
    },
    buttonText: {
        paddingTop: "5px"
    },
    buttonStyle: {
        height: "44px",
        textTransform: "none",
        color: "#4F46E5",
        borderRadius: "100px",
        padding: "0 16px",
        width: "130px"
    } as React.CSSProperties,
    buttonInnerBox: {
        display: "flex",
        alignItems: "center"
    },
    buttonIcon: {
        fontSize: "26px",
        fontWeight: 600
    }
};
// Customizable Area End