// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const threeDots = require('../assets/threedots.png');
export const folder = require('../assets/folder.png');
export const file = require('../assets/file.png');
export const backButton = require('../assets/Backbtn.png');
export const folderThreeDot = require('../assets/folderThreeDots.png');
export const uploadIcon = require('../assets/uploadIcon.svg').default;
export const filterIcon = require('../assets/filterIcon.svg').default;
export const userImage = require('../assets/userImage.png')

// Customizable Area End
